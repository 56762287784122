.operator .ant-btn:hover,
.operator .ant-btn:focus {
    color: $black;
}

// start line-clamp
.line-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// end line-clamp
// start checkbox
.form-check {
    position: relative;
    display: block;
    transform: translateZ(10px);
    -webkit-transform: translateZ(10px);
    -moz-transform: translateZ(10px);
    -ms-transform: translateZ(10px);
    -o-transform: translateZ(10px);
    z-index: 0;
}

.form-check--input {
    display: none;
}

.form-check--label {
    font-weight: 400;
    line-height: 24px;
    color: #111111;
    cursor: pointer;
    padding-left: 20px;
    z-index: 0;
}

.form-check--label::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $grey1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    z-index: 1;
}

.form-check--label::after {
    content: "";
    position: absolute;
    display: none;
    background: url("../assets/images/tickCheckbox.svg") no-repeat;
    width: 17px;
    height: 17px;
    top: 6px;
    left: 5px;
    z-index: 1;
}

.form-check input[type="checkbox"]:checked ~ label::after {
    display: block;
}

.center {
    width: 30px;
    margin: auto;
}

// end checkbox
// start sidenav
.c-sidebar {
    background: #1a2226;
}

@media (min-width: 992px) {
    .c-sidebar-nav-dropdown {
        &:nth-last-child(1):hover > .c-sidebar-nav-dropdown-items {
            overflow: auto;
            max-height: 485px;
            .c-sidebar-nav-link.c-active {
                border-left: 0 !important;
            }
            &::-webkit-scrollbar {
                width: 0px;
                height: 10px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #c4c4c4;
            }
        }
    }
}

.c-sidebar-nav > .c-sidebar-nav-dropdown,
.c-sidebar-nav > .c-sidebar-nav-item {
    margin-bottom: 6px;
    background: #222d32;
}

.c-sidebar-nav-link.c-active {
    border-left: 4px solid $dark-blue;
}

.c-sidebar .c-sidebar-brand {
    background: #0c1db3;
}

.font-size-16 {
    font-size: $font-size-base;
}

.font-size-12 {
    font-size: $font-size-sm;
}

.full-width {
    width: 100%;
}

.modal-full-width {
    .modal-dialog {
        width: 80vw;
        max-width: 80vw;
        margin: auto;
    }
    .ant-select {
        min-width: 40%;
    }
}

.table-class {
    border-top: 2px solid #a4a4a4 !important;
    & tr:focus {
        background-color: #e8eaff !important;
    }
}

.row-selected {
    background-color: #e8eaff !important;
}

.checkbox-grey {
    .ant-checkbox-checked .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background: transparent !important;
        border-color: #929292 !important;
        &::after {
            height: 12px;
            border-color: $black !important;
            opacity: 0.5;
            transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
        }
    }
}

.checkbox-blue {
    .ant-checkbox-checked .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background: transparent !important;
        border-color: $torea !important;
        &::after {
            height: 12px;
            border-color: $torea !important;
            transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
        }
    }
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
}

.border-bottom-grey {
    border-bottom: 1px solid #c3c6dd;
}

// end checkbox
.text-direction {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.c-body .c-main table.table.table-bordered.tbNoBorder th,
.c-body .c-main table.table.table-bordered.tbNoBorder td {
    // border-bottom: 1px solid;
    border-right: none !important;
    border-left: none !important;
}

.c-body .c-main table.table.table-bordered.tbNoBorder tr:last-child td {
    border-bottom: none;
    border-right: none !important;
    border-left: none !important;
}

.c-body .c-main table.table.tbNoBorder > thead th {
    border-top: none;
}

table.table {
    border-color: #d7d7d7 !important;
    border-top: 2px solid #c3c6dd !important;
}

.itemTotalDashboard {
    text-align: right;
    font-weight: bold;
}

.ant-select-clear {
    line-height: 0 !important;
}

.labelSearch {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    height: 20px;
    line-height: 20px;
    font-size: 12px !important;
    margin-bottom: 0 !important;
}

.react-pdf__Document {
    max-height: 60vh;
    overflow-y: auto;
}

.react-pdf__Page__canvas {
    margin: auto;
    width: 100%;
    min-width: 400px;
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }
}

.disabled {
    color: rgba(0, 0, 0, 0.65);
}
